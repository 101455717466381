<template>
  <v-snackbar
    :id="`${snack.color}-snackbar`"
    v-model="snack.show"
    class="snackbar__alert"
    :color="snack.color"
    :timeout="snack.timeout"
    transition="slide-y-transition"
    top
    style="white-space: preserve nowrap;"
  >
    {{ formatErrorMessage(snack.message) }}
    <template #action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        x-small
        @click="reset"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackbarModal',
  computed: {
    snack() {
      return this.$store.getters['snackbar/snack'];
    }
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    formatErrorMessage(error) {
      if (typeof error === 'string') {
        return error;
      } else {
        return 'Something went wrong, please discard and try again later.';
      }
    },
    reset() {
      // Reset message needs to be of type string so the 'Something went wrong....' snackbar doesn't show up after the first snackbar is closed
      this.$store.commit('snackbar/setSnack', {
        show: false,
        timeout: -1,
        message: '',
        color: 'success'
      });
    }
  }
};
</script>
<style>
.snackbar__alert {
  opacity: 90%;
  margin-top: 5rem;
}
</style>
