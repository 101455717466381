<template>
    <v-dialog
      v-model="isVisible"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-center">
          {{  percentDone }}%
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="loadingProgress"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'LoadingModal',
  props: {
    visible: Boolean,
    loadingProgress: Number,
    totalBytes: Number,
    totalBytesSent: Number
  },
  computed: {
    percentDone() {
      return Math.round((this.totalBytesSent / this.totalBytes) * 100);
    },
    isVisible() {
      return this.visible;
    }
  }
};

</script>
