const initState = () => {
  return {
    snack: {}
  };
};

const state = {
  initState,
  ...initState()
};

const getters = {
  snack: (state) => state.snack
};

const mutations = {
  setSnack(state, snack) {
    state.snack = snack;
  }
};

const actions = {
  success({ commit }, message) {
    commit('setSnack', {
      show: true,
      timeout: 100,
      message,
      color: 'success'
    });
  },
  error({ commit }, message) {
    commit('setSnack', {
      show: true,
      timeout: 10000,
      message,
      color: 'error'
    });
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
