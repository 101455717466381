<template>
  <v-container>
    <vue-dropzone
      id="file-uploader__dropzone"
      ref="myVueDropzone"
      :options="dropzoneOptions"
      :use-custom-slot="true"
      :duplicateCheck="true"
      @vdropzone-drop="waitUpdateFiles"
      @vdropzone-file-added="updateFiles"
      @vdropzone-files-added="updateFiles"
      @vdropzone-success="successEvent"
      @vdropzone-removed-file="updateFiles"
      @vdropzone-total-upload-progress="updateLoading"
      @vdropzone-error="errorEvent"
      @vdropzone-duplicate-file="duplicateEvent"
    >
      <v-icon size="4rem" color="primary">$vuetify.icons.fileCirclePlus</v-icon>
      <p class="file-uploader__description-text overline text--secondary">
        To transfer files, drag and drop the files here <span class="font-weight-black" >OR</span> click here to select the files.
      </p>
    </vue-dropzone>
    <v-card-actions class="justify-center">
      <div class="d-flex mb-4">
        <v-btn
          class="mr-4"
          color="primary"
          text
          @click="clearFiles"
        >
          Discard All
        </v-btn>
        <v-btn
          color="primary"
          outlined
          :disabled="!files.length"
          @click="uploadFiles"
        >
          Upload Files
        </v-btn>
      </div>
    </v-card-actions>
    <loading-modal
      :visible.sync="isLoading"
      :loading-progress="loadingProgress"
      :totalBytes="totalBytes"
      :totalBytesSent="totalBytesSent"
    />
  </v-container>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import vueDropzone from 'vue2-dropzone';
import LoadingModal from '@/components/modals/LoadingModal.vue';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'FileUploader',
  components: {
    vueDropzone,
    LoadingModal
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_BACKEND_URL}/upload`,
        thumbnailWidth: 150,
        paramName: 'files',
        autoProcessQueue: false,
        headers: $.ajaxSetup().headers,
        addRemoveLinks: true,
        maxFilesize: process.env.VUE_APP_MAX_UPLOAD_FILESIZE_MB,
        chunkSize: process.env.VUE_APP_UPLOAD_CHUNK_SIZE,
        timeout: null
      },
      conflictingFiles: [],
      uploadWarnings: [],
      loadingProgress: 1,
      isConflictsModalVisible: false,
      totalBytes: 0,
      totalBytesSent: 0,
      isLoading: false
    };
  },
  computed: {
    files() {
      return this.$store.getters.files;
    }
  },
  methods: {
    uploadFiles() {
      this.$refs.myVueDropzone.processQueue();
    },
    clearFiles() {
      this.$refs.myVueDropzone.removeAllFiles();
    },
    // allows for dropzone queue to be updated before calling updateFiles on file drop
    waitUpdateFiles() {
      setTimeout(this.updateFiles, 5);
    },
    updateFiles() {
      this.$store.commit('setFiles', this.$refs.myVueDropzone.getQueuedFiles());
    },
    errorEvent(file, message) {
      this.isLoading = false;
      this.$store.commit('snackbar/setSnack', {
        show: true,
        timeout: 10000,
        message: message.detail,
        color: 'error'
      });
    },
    successEvent(file, response) {
      !_isEmpty(response) && this.uploadWarnings.push(`${file.name} : ${Object.values(response[0])}`);
      this.$refs.myVueDropzone.removeFile(file);
      if (this.$refs.myVueDropzone.getUploadingFiles().length === 0) {
        this.$store.commit('snackbar/setSnack', {
          show: true,
          timeout: 10000,
          message: _isEmpty(this.uploadWarnings)
            ? 'All files successfully uploaded to landing directory'
            : `These files could not be uploaded:\n- ${this.uploadWarnings.join('\n- ')}`,
          color: _isEmpty(this.uploadWarnings)
            ? 'success'
            : 'error'
        });
        this.uploadWarnings = [];
      }
    },
    duplicateEvent(file) {
      this.$store.commit('snackbar/setSnack', {
        show: true,
        timeout: 2000,
        message: `${file.name} already added to dropzone`,
        color: 'warning'
      });
    },
    updateLoading(totalUploadProgress, totalBytes, totalBytesSent) {
      this.isLoading = (this.$refs.myVueDropzone.getUploadingFiles().length > 0);
      this.loadingProgress = totalUploadProgress;
      this.totalBytesSent = totalBytesSent;
      this.totalBytes = totalBytes;
    }
  }
};

</script>

<style>
.file-uploader__description-text {
  margin: 5% 2.5rem 0;
  text-align: center;
}
#file-uploader__dropzone {
  background-color: var(--v-secondary-base);
  border: thin solid var(--v-primary-base);
  border-radius: 4px;
  margin: 5% 2.5rem 1rem;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#file-uploader__dropzone:hover {
  background-color: var(--v-accent-base);
}
#file-uploader__dropzone .dz-remove {
  border: none;
  margin: 0 auto 10px;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.775rem;
  width: 100%;
  text-decoration: none;
}
#file-uploader__dropzone .dz-preview.dz-file-preview .dz-image {
  background: var(--v-primary-base);
}
#file-uploader__dropzone .dz-preview .dz-image {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}
#file-uploader__dropzone .dz-preview .dz-image > img {
  width: 100%;
}
#file-uploader__dropzone .dz-preview .dz-details {
  transition: opacity .2s linear;
  text-align: center;
  border-radius: 50%;
  padding-top: 30%;
}
#file-uploader__dropzone .dz-success-mark, .dz-error-mark, .dz-progress{
  display: none;
}

/* I apologize for my sins */
.vue-dropzone>.dz-preview .dz-details {
  background-color: transparent;
}
.dropzone .dz-preview.dz-image-preview {
  background: none;
}
.dropzone .dz-preview .dz-remove:hover {
  color: var(--v-accent2-base);
}
.vue-dropzone>.dz-preview .dz-image img:not([src]) {
  height: 0;
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  display: none;
}
</style>
